import { OrdersApi, OrderSearchListItem } from '@idealsupply/ngclient-webservice-orders';
import { IPagedResult, PagedDataSource } from 'lib-products';
import { firstValueFrom } from 'rxjs';

export interface IOrderListFilter {
  lineCode?: string;
  partNumber?: string;
  customerNumber?: string;
  orderNumber?: number;
  invoiceNumber?: number;
  customerPo?: string;
  shipVia?: string;
  startDate?: string;
  endDate?: string;
  orderType?: 'Invoiced' | 'Open' | 'BackOrder' | 'All';
}

export class OrderListDataSource extends PagedDataSource<OrderSearchListItem> {
  private _filter: IOrderListFilter = {};
  /*
  private _customerNumber?: string;
  private _shipVia?: string;
  private _customerPo?: string;
  private _orderNumber?: number;
  private _invoiceNumber?: number;
  private _lineCode?: string;
  private _partNumber?: string;
  private _startDate?: string;
  private _endDate?: string;
  private _orderType?: 'Invoiced' | 'Open' | 'BackOrder' | 'All';
*/
  constructor(
    private readonly orderApi: OrdersApi,
    skip: number = 0,
    limit: number = -1,
    /*customerNumber?: string,
    startDate?: string,
    endDate?: string,*/
  ) {
    super(skip, limit);
    /*this._customerNumber = customerNumber;
    this._startDate = startDate;
    this._endDate = endDate;
    */
  }

  private cleanFilter(data: IOrderListFilter): IOrderListFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const val = (data as any)[key];
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  private _compareFilter(a?: IOrderListFilter, b?: IOrderListFilter): boolean {
    return JSON.stringify(this.cleanFilter(a ?? {})) === JSON.stringify(this.cleanFilter(b ?? {}));
  }

  private _isFilterEmpty(a?: IOrderListFilter): boolean {
    return this._compareFilter(a, undefined);
  }

  protected update(): Promise<IPagedResult<OrderSearchListItem>> {
    const f = this._filter ?? {};
    this.cleanFilter(f);

    return firstValueFrom(
      this.orderApi.getOrders(
        this.skip,
        this.limit || 10,
        f.customerNumber,
        f.shipVia,
        f.customerPo,
        f.orderNumber,
        f.invoiceNumber,
        f.lineCode,
        f.partNumber,
        f.startDate,
        f.endDate,
        f.orderType,
      ),
    ) as unknown as Promise<IPagedResult<OrderSearchListItem>>;
  }

  public get customerNumber(): string | undefined {
    return this._filter.customerNumber;
  }

  public set customerNumber(value: string | undefined) {
    value = value || undefined;
    if (this.customerNumber !== value) {
      this.changed = true;
      this._filter.customerNumber = value;
      this.invalidateResults();
    }
  }

  public get shipVia(): string | undefined {
    return this._filter.shipVia;
  }

  public set shipVia(value: string | undefined) {
    value = value || undefined;
    if (this.shipVia !== value) {
      this.changed = true;
      this._filter.shipVia = value;
      this.invalidateResults();
    }
  }
  public get customerPo(): string | undefined {
    return this._filter.customerPo;
  }

  public set customerPo(value: string | undefined) {
    value = value || undefined;
    if (this.customerPo !== value) {
      this.changed = true;
      this._filter.customerPo = value;
      this.invalidateResults();
    }
  }
  public get orderNumber(): number | undefined {
    return this._filter.orderNumber;
  }

  public set orderNumber(value: number | undefined) {
    value = value || undefined;
    if (this.orderNumber !== value) {
      this.changed = true;
      this._filter.orderNumber = value;
      this.invalidateResults();
    }
  }
  public get invoiceNumber(): number | undefined {
    return this._filter.invoiceNumber;
  }

  public set invoiceNumber(value: number | undefined) {
    value = value || undefined;
    if (this.invoiceNumber !== value) {
      this.changed = true;
      this._filter.invoiceNumber = value;
      this.invalidateResults();
    }
  }
  public get lineCode(): string | undefined {
    return this._filter.lineCode;
  }

  public set lineCode(value: string | undefined) {
    value = value || undefined;
    if (this.lineCode !== value) {
      this.changed = true;
      this._filter.lineCode = value;
      this.invalidateResults();
    }
  }
  public get partNumber(): string | undefined {
    return this._filter.partNumber;
  }

  public set partNumber(value: string | undefined) {
    value = value || undefined;
    if (this.partNumber !== value) {
      this.changed = true;
      this._filter.partNumber = value;
      this.invalidateResults();
    }
  }
  public get orderType(): 'Invoiced' | 'Open' | 'BackOrder' | 'All' | undefined {
    return this._filter.orderType;
  }

  public set orderType(value: 'Invoiced' | 'Open' | 'BackOrder' | 'All' | undefined) {
    value = value || undefined;
    if (this.orderType !== value) {
      this.changed = true;
      this._filter.orderType = value;
      this.invalidateResults();
    }
  }

  public get startDate(): string | undefined {
    return this._filter.startDate;
  }

  public set startDate(value: string | undefined) {
    value = value || undefined;
    if (this.startDate !== value) {
      this.changed = true;
      this._filter.startDate = value;
      this.invalidateResults();
    }
  }

  public get endDate(): string | undefined {
    return this._filter.endDate;
  }

  public set endDate(value: string | undefined) {
    value = value || undefined;
    if (this.endDate !== value) {
      this.changed = true;
      this._filter.endDate = value;
      this.invalidateResults();
    }
  }

  public get filter(): IOrderListFilter {
    return this._filter;
  }

  public set filter(value: IOrderListFilter) {
    value ??= {};
    if (JSON.stringify(this.filter) !== JSON.stringify(value)) {
      this.changed = true;
      this._filter = value;
      this.invalidateResults();
    }
  }

  public override initialize(value?: IOrderListFilter): Promise<this> {
    this._filter = value ?? {};
    return super.initialize();
  }
}
