<div class="cc-type-list">
  <img *ngFor="let cc of creditCardTypes" class="cc-logo" [src]="'https://cdn.na.bambora.com/downloads/images/cards/' + cc + '.svg'" />
</div>

<div class="form-container" [formGroup]="ccForm">
  <mat-form-field [appearance]="appearance" class="card-name" [color]="color">
    <mat-label>Name</mat-label>
    <input matInput type="text" autocomplete="cc-name" formControlName="name" inputmode="text" />
    <mat-hint>The name on the card.</mat-hint>
    <mat-error *ngIf="hasErr('name', 'required')"> The name on the card is required. </mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="appearance" class="card-number bam" [color]="color" #cardNumberWrapper="matFormField">
    <mat-label>Card number</mat-label>
    <input #cardNumber="matInput" matInput type="text" formControlName="number" inputmode="numeric" />

    <div id="card-number" class="cc-field"></div>

    <img
      class="cc-logo"
      [hidden]="!cardType"
      matSuffix
      [src]="cardType ? 'https://cdn.na.bambora.com/downloads/images/cards/' + cardType + '.svg' : ''"
    />
    <mat-hint>The Credit card number.</mat-hint>
    <mat-error *ngIf="hasErr('number', 'required')"> The credit card number is required. </mat-error>
    <mat-error *ngIf="hasErr('number', 'cc-unsupported')"> Unfortunately we do not currently accept {{ cardName }} cards. </mat-error>
    <mat-error *ngIf="hasErr('number', 'CardNumberInvalid') || hasErr('number', 'CardNumberInvalid')">
      The credit card number is invalid.
    </mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="appearance" class="card-exp bam" [color]="color" #cardExpiryWrapper="matFormField">
    <mat-label>Expiration</mat-label>
    <input matInput #cardExpiry="matInput" type="text" autocomplete="cc-exp" formControlName="expiry" inputmode="numeric" />

    <div id="card-expiry" class="cc-field"></div>

    <mat-hint>The expiration on the card.</mat-hint>
    <mat-error *ngIf="hasErr('expiry', 'required')"> The expiration is required. </mat-error>
    <mat-error *ngIf="hasErr('expiry', 'ExpiryIsInThePast') || hasErr('expiry', 'ExpiryIsNotSet')">
      {{ bamboraFields.expiry.error?.message }}
    </mat-error>
  </mat-form-field>

  <mat-form-field [appearance]="appearance" class="card-sec-code bam" [color]="color" #cardCvvWrapper="matFormField">
    <mat-label>Security code</mat-label>
    <input matInput #cardCvv="matInput" type="text" autocomplete="cc-csc" formControlName="cvv" inputmode="numeric" />

    <div id="card-cvv" class="cc-field"></div>
    <mat-hint>The security code on the card.</mat-hint>
    <mat-error *ngIf="hasErr('cvv', 'required')"> The security code is required. </mat-error>
    <mat-error *ngIf="hasErr('cvv', 'CvvNotSet')">
      {{ bamboraFields.cvv.error?.message }}
    </mat-error>
  </mat-form-field>
</div>
