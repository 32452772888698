import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldAppearance, MatFormField, MatLabel, MatHint, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CreditCard } from '@idealsupply/ngclient-webservice-orders';
import { debounceTime } from 'rxjs/operators';
import { OrdersService } from '../orders/orders.service';
import { ThemePalette } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';

export const SUPPORTED_CREDITCARDS: InjectionToken<string[]> = new InjectionToken('Supported Credit Cards', {
  providedIn: 'root',
  factory: () => {
    return ['mastercard', 'visa'];
  },
});

function elevateErrors(control: AbstractControl): ValidationErrors | null {
  let err = false;
  let errs = {};
  const grp = control as FormGroup;
  for (const key in grp.controls) {
    if (Object.prototype.hasOwnProperty.call(grp.controls, key)) {
      const ctrl = grp.controls[key];
      if (ctrl.invalid) {
        err = true;
        errs = {
          ...errs,
          ...ctrl.errors,
        };
      }
    }
  }

  return err ? errs : null;
}

interface BamboraField {
  input: any;
  matInput?: MatInput;
  dummyInput?: HTMLInputElement;
  wrapper?: HTMLElement;
  formControl?: AbstractControl;
  placeholder?: string;
  empty: boolean;
  complete: boolean;
  focus: boolean;
  error?: { field: BamboraFieldType; type: string; message: string };
}

interface BamboraFields {
  'card-number': BamboraField;
  expiry: BamboraField;
  cvv: BamboraField;
}

type BamboraFieldType = 'card-number' | 'expiry' | 'cvv';

@Component({
  selector: 'credit-card-control',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CreditCardComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CreditCardComponent,
    },
  ],
  standalone: true,
  imports: [NgFor, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatHint, NgIf, MatError, MatSuffix],
})
export class CreditCardComponent implements AfterContentInit, ControlValueAccessor, Validator, OnDestroy {
  private _destroyed: boolean = false;

  public readonly bamboraFields: BamboraFields = {
    'card-number': {
      input: null,
      complete: false,
      empty: true,
      focus: false,
    },
    expiry: {
      input: null,
      complete: false,
      empty: true,
      focus: false,
      placeholder: 'MM / YY',
    },
    cvv: {
      input: null,
      complete: false,
      empty: true,
      focus: false,
    },
  };
  private _cardType?: string;
  private _cardBrand?: string;

  private onValidatorChange: Function = () => {};

  private static _bamInputs: Map<string, any> = new Map();
  public ccForm: FormGroup;

  @Input()
  public appearance: MatFormFieldAppearance = 'outline';

  @Input()
  public color: ThemePalette = 'primary';

  @Input()
  public disabled: boolean = false;

  @Input()
  public creditCardTypes: string[];

  @ViewChild('cardNumber', { static: true })
  private cardNumberField!: MatInput;

  @ViewChild('cardNumber', { static: true, read: ElementRef })
  private cardNumberInput!: ElementRef<HTMLInputElement>;

  @ViewChild('cardNumberWrapper', { static: true, read: ElementRef })
  private cardNumberWrapper!: ElementRef<HTMLElement>;

  @ViewChild('cardCvv', { static: true })
  private cardCvvField!: MatInput;

  @ViewChild('cardCvv', { static: true, read: ElementRef })
  private cardCvvInput!: ElementRef<HTMLInputElement>;

  @ViewChild('cardCvvWrapper', { static: true, read: ElementRef })
  private cardCvvWrapper!: ElementRef<HTMLElement>;

  @ViewChild('cardExpiry', { static: true })
  private cardExpiryField!: MatInput;

  @ViewChild('cardExpiry', { static: true, read: ElementRef })
  private cardExpiryInput!: ElementRef<HTMLInputElement>;

  @ViewChild('cardExpiryWrapper', { static: true, read: ElementRef })
  private cardExpiryWrapper!: ElementRef<HTMLElement>;

  private baseFieldOpts = {
    placeholder: '',
    style: {
      base: {
        paddingLeft: '0em',
        paddingTop: '0.8em',
        paddingRight: '0em',
        paddingBottom: '0em',
        fontSize: '16px',
        fontFamily: 'Roboto, sans-serif',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  };

  constructor(
    @Inject(SUPPORTED_CREDITCARDS) defaultSupportedCreditCards: string[],
    private readonly changeRef: ChangeDetectorRef,
    private readonly orderService: OrdersService,
  ) {
    this.creditCardTypes = defaultSupportedCreditCards ?? [];
    this.ccForm = new FormGroup(
      {
        name: new FormControl('', { validators: [Validators.required] }),
        number: new FormControl('', {
          validators: [this.validateBamboraField('card-number'), this.validateCardType.bind(this)],
        }),
        expiry: new FormControl('', {
          validators: [this.validateBamboraField('expiry')],
        }),
        cvv: new FormControl('', {
          validators: [this.validateBamboraField('cvv')],
        }),
      },
      { validators: [this.validateComplete.bind(this), elevateErrors] },
    );
  }

  private validateComplete(control: AbstractControl): ValidationErrors | null {
    let result: ValidationErrors | null = null;
    this.forEachField((field) => {
      if (!field.complete) {
        result = {
          required: true,
        };
      }
    });
    return result;
  }

  private validateCardType(control: AbstractControl): ValidationErrors | null {
    if (!this.cardType && !this.bamboraFields['card-number'].empty && this._cardBrand && this._cardBrand !== 'unknown') {
      return {
        'cc-unsupported': true,
      };
    }
    return null;
  }

  private validateBamboraField(key: BamboraFieldType): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const field = this.bamboraFields[key];

      if (field.complete) {
        return null;
      }
      if (field.empty) {
        return {
          required: true,
        };
      }
      if (field.error) {
        const r = {
          [field.error.type]: field.error,
        };
        return r;
      }
      return { [key]: true };
    };
  }

  public forEachField(cb: (input: BamboraField) => void) {
    for (const key in this.bamboraFields) {
      if (Object.prototype.hasOwnProperty.call(this.bamboraFields, key)) {
        cb((this.bamboraFields as any)[key]);
      }
    }
  }

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.initializeBamboraFields();
    });
  }

  public ngOnDestroy() {
    this._destroyed = true;
    this.forEachField((field) => {
      field.input.clear();
      field.input.unmount();
    });
  }

  private initializeBamboraFields() {
    const ccApi = this.orderService.ccApi;
    const opts = this.baseFieldOpts;

    if (!CreditCardComponent._bamInputs.has('card-number')) {
      CreditCardComponent._bamInputs.set('card-number', ccApi.create('card-number', { ...opts, brands: this.creditCardTypes }));
    }
    if (!CreditCardComponent._bamInputs.has('cvv')) {
      CreditCardComponent._bamInputs.set('cvv', ccApi.create('cvv', { ...opts }));
    }
    if (!CreditCardComponent._bamInputs.has('expiry')) {
      CreditCardComponent._bamInputs.set('expiry', ccApi.create('expiry', { ...opts }));
    }

    this.bamboraFields['card-number'].input = CreditCardComponent._bamInputs.get('card-number');

    this.bamboraFields['card-number'].input.mount('#card-number');
    this.bamboraFields['card-number'].matInput = this.cardNumberField;
    this.bamboraFields['card-number'].dummyInput = this.cardNumberInput.nativeElement;
    this.bamboraFields['card-number'].wrapper = this.cardNumberWrapper.nativeElement;
    this.bamboraFields['card-number'].formControl = this.ccForm.get('number')!;

    this.bamboraFields.cvv.input = CreditCardComponent._bamInputs.get('cvv');
    this.bamboraFields.cvv.input.mount('#card-cvv');
    this.bamboraFields.cvv.matInput = this.cardCvvField;
    this.bamboraFields.cvv.dummyInput = this.cardCvvInput.nativeElement;
    this.bamboraFields.cvv.wrapper = this.cardCvvWrapper.nativeElement;
    this.bamboraFields.cvv.formControl = this.ccForm.get('cvv')!;

    this.bamboraFields.expiry.input = CreditCardComponent._bamInputs.get('expiry');
    this.bamboraFields.expiry.input.mount('#card-expiry');
    this.bamboraFields.expiry.matInput = this.cardExpiryField;
    this.bamboraFields.expiry.dummyInput = this.cardExpiryInput.nativeElement;
    this.bamboraFields.expiry.wrapper = this.cardExpiryWrapper.nativeElement;
    this.bamboraFields.expiry.formControl = this.ccForm.get('expiry')!;

    ccApi.on('brand', (evt: { brand: string }) => {
      this._cardBrand = evt.brand;
      const cardType = this.creditCardTypes.indexOf(evt.brand) === -1 ? undefined : evt.brand;
      if (this._cardType !== cardType) {
        this._cardType = cardType;
        this.changeRef.markForCheck();
      }
    });

    ccApi.on('blur', (evt: { field: BamboraFieldType }) => {
      if (this._destroyed) return;
      this.bamboraFields[evt.field].focus = false;
      this.updateFieldState();
    });

    ccApi.on('focus', (evt: { field: BamboraFieldType }) => {
      if (this._destroyed) return;
      this.bamboraFields[evt.field].focus = true;
      this.bamboraFields[evt.field].formControl?.markAsTouched();
      this.updateFieldState(true);
    });

    ccApi.on('empty', (evt: { field: BamboraFieldType; empty: boolean }) => {
      if (this._destroyed) return;
      this.bamboraFields[evt.field].empty = evt.empty;
      this.updateFieldState();
    });

    ccApi.on('complete', (evt: { field: BamboraFieldType; complete: boolean }) => {
      if (this._destroyed) return;
      this.bamboraFields[evt.field].complete = evt.complete;
      if (evt.complete) {
        this.bamboraFields[evt.field].error = undefined;
      }
      this.updateFieldState();
    });

    ccApi.on('error', (evt: { field: BamboraFieldType; type: string; message: string }) => {
      if (this._destroyed) return;
      this.bamboraFields[evt.field].error = evt;
      this.updateFieldState();
    });
  }

  public onNameChanged() {}

  private updateFieldState(isFocus: boolean = false) {
    this.forEachField((field) => {
      const matInput: MatInput = field.matInput!;
      const formControl: AbstractControl = field.formControl!;
      const input: HTMLInputElement = field.dummyInput!;

      if (field.complete) {
        if (!isFocus || formControl.value !== 'complete') {
          formControl.setValue('complete');
        }
      } else if (field.focus || !field.empty) {
        formControl.setValue(' ');
      } else if (!field.focus) {
        formControl.setValue('');
      }

      if (field.focus) {
        field.wrapper!.classList.add('mat-focused');
      } else {
        field.wrapper!.classList.remove('mat-focused');
      }

      const opts = JSON.parse(JSON.stringify(this.baseFieldOpts));

      if (field.focus && field.empty) {
        opts.placeholder = field.placeholder || ' ';
      } else {
        opts.placeholder = ' ';
      }

      field.input.update(opts);
    });
  }

  writeValue(value: CreditCard): void {
    if (!value) {
      this.forEachField((field) => {
        field.input?.clear();
      });
    }
  }

  registerOnChange(fn: any): void {
    this.ccForm.valueChanges.pipe(debounceTime(250)).subscribe(async (v) => {
      if (!this.ccForm.valid) {
        fn(undefined);
      } else {
        fn({
          name: v.name,
          cardType: this.cardType,
        });
      }
    });
  }

  public registerOnTouched(fn: any): void {
    this.ccForm.statusChanges.subscribe(() => {
      if (this.ccForm.touched) {
        fn();
      }
    });
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.onValidatorChange();
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public hasErr(controlName: string, errorName: string): boolean {
    return this.ccForm.get(controlName)?.hasError(errorName) ?? false;
  }

  public getErr(controlName: string, errorName: string): any {
    return this.ccForm.get(controlName)?.getError(errorName);
  }

  public get cardType(): string | undefined {
    return this._cardType;
  }

  public get cardName(): string | undefined {
    switch (this._cardBrand) {
      case 'amex':
        return 'American Express';
      case 'diners':
        return 'Diners Club';
      case 'discover':
        return 'Discover';
      case 'jcb':
        return 'JCB';
      case 'maestro':
        return 'Maestro';
      case 'mastercard':
        return 'MasterCard';
      case 'visa':
        return 'Visa';
      default:
        return this._cardBrand;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.disabled ? null : this.ccForm.errors;
  }
}
