import { Injectable } from '@angular/core';
// import { AuthenticationService } from '@conceptualpathways/cpoauth';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  CreditCard,
  Order,
  OrderBilling,
  OrderCartRequest,
  OrdersApi,
  OrderSearchListResult,
  OrderType,
} from '@idealsupply/ngclient-webservice-orders';
import { AuthenticationService } from 'authentication-data';
import { CartService } from 'lib-cart';
import { CustomerService } from 'lib-customer';
import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOrderData } from './IOrderData';

declare const customcheckout: () => any;

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private static _ccApi: any;

  private _orderCompleted$$ = new Subject<Order>();
  public readonly orderCompleted$ = this._orderCompleted$$.asObservable();

  private _orderData: IOrderData = {} as IOrderData;

  public readonly onHold$ = combineLatest([toObservable(this.authService.user), this.customerService.customerNumberChange]).pipe(
    map(([user, customerNumber]) => {
      const onHoldNumbers = (
        Array.isArray(user?.['customer_number_on_hold'] ?? [])
          ? (user?.['customer_number_on_hold'] ?? [])
          : [user?.['customer_number_on_hold']]
      ) as string[];

      return customerNumber ? onHoldNumbers.includes(customerNumber.customerNumber!) : false;
    }),
  );

  constructor(
    private readonly ordersApi: OrdersApi,
    private readonly cartService: CartService,
    private readonly customerService: CustomerService,
    private readonly authService: AuthenticationService,
  ) {
    customerService.customerNumberChange.subscribe((custNo) => {});
    cartService.cartChange.subscribe((cart) => {
      this.loadOrderData();
    });
    if (!OrdersService._ccApi) {
      OrdersService._ccApi = typeof customcheckout !== 'undefined' ? customcheckout() : undefined;
    }
  }

  public get ccApi() {
    return OrdersService._ccApi;
  }

  private getCcToken(ccInfo: { name: string; cardType: string }): Promise<CreditCard> {
    return new Promise((res, rej) => {
      this.ccApi.createToken(
        ({
          code,
          expiryMonth,
          expiryYear,
          last4,
          token,
        }: {
          code: number;
          expiryMonth: string;
          expiryYear: string;
          last4: string;
          token: string;
        }) => {
          const name = ccInfo.name;
          const type = ccInfo.cardType;
          if (code === 200) {
            const model: CreditCard = {
              name,
              type,
              expiryMonth,
              expiryYear,
              last4,
              token,
            };
            res(model);
          } else {
            rej(code);
          }
        },
      );
    });
  }

  private get dataPath(): string {
    return `com.idealsupply.api.orders__${this.cartService.cart?.id}`;
  }

  private loadOrderData(): void {
    const data = typeof sessionStorage !== undefined ? sessionStorage.getItem(this.dataPath) : undefined;
    this._orderData = data ? JSON.parse(data) : {};
  }

  private saveOrderData(): void {
    if (this._orderData.cartId === this.cartService.cart?.id) {
      const cpy = { ...this._orderData };
      delete cpy.billing.cc;
      sessionStorage.setItem(this.dataPath, JSON.stringify(cpy));
    }
  }

  private deleteOrderData(): void {
    this._orderData = {} as IOrderData;
    sessionStorage.removeItem(this.dataPath);
  }

  public set orderData(data: IOrderData) {
    this._orderData = data;

    this.saveOrderData();
  }
  public get orderData(): IOrderData {
    return this._orderData;
  }

  public async submitOrder(ccInfo?: { name: string; cardType: string }): Promise<Order> {
    const method = this._orderData.type.method;

    const orderBilling: OrderBilling = {
      email:
        this.customerService.customerNumber && this.customerService.customerNumber.customerNumber !== '000002'
          ? undefined
          : this.orderData.billing.email,
      phone: this.orderData.billing.phone,
      useShippingAddress: this.orderData.billing.useShippingAddress,
      paymentMethod:
        this.orderData.billing.method === OrderBilling.PaymentMethodEnum.Card
          ? OrderBilling.PaymentMethodEnum.Card
          : OrderBilling.PaymentMethodEnum.Account,
    };

    if (orderBilling.useShippingAddress) {
      if (this._orderData.type.shippingAddressType === 1) {
        orderBilling.address = this._orderData.type.shippingAddressCustom;
      } else {
        orderBilling.address = this._orderData.type.shippingAddress;
      }
    } else {
      orderBilling.address = this._orderData.billing.address;
    }

    if (this.orderData.billing.method === OrderBilling.PaymentMethodEnum.Card) {
      const cc = await this.getCcToken(ccInfo!);
      orderBilling.cc = { ...cc };
      orderBilling.cc.name = ccInfo?.name!;
      orderBilling.cc.type = ccInfo?.cardType!;
    }

    const orderType: OrderType = {
      deliveryMethod:
        this._orderData.type.method === OrderType.DeliveryMethodEnum.Delivery
          ? OrderType.DeliveryMethodEnum.Delivery
          : OrderType.DeliveryMethodEnum.Pickup,
      specialInstructions: this._orderData.type.specialInstructions,
      comment: this.orderData.type.comment,
      purchaseOrderNumber: this.orderData.billing.purchaseOrderNumber,
    };

    if (orderType.deliveryMethod === OrderType.DeliveryMethodEnum.Delivery) {
      orderType.shippingId = this._orderData.type.shippingId;
      if (this._orderData.type.shippingAddressType === 1) {
        orderType.shippingAddress = this._orderData.type.shippingAddressCustom;
        orderType.shippingId = -1;
      } else {
        orderType.shippingAddress = this._orderData.type.shippingAddress;
      }
    } else {
      orderType.pickupId = this._orderData.type.pickupId;
      orderType.pickupAddress = this._orderData.type.pickupAddress;
    }

    const newOrder: OrderCartRequest = {
      cartId: this._orderData.cartId,
      customerNumber: this.customerService.customerNumber?.customerNumber || '000002',
      orderBilling,
      orderType,
      deliveryTimeFrame: this._orderData.type.deliveryTimeFrame,
      deliveryDate: this._orderData.type.deliveryDate,
      additionalComments: this._orderData.type.additionalComments,
    };

    const order = await firstValueFrom(this.ordersApi.submitOrder(newOrder));

    this.deleteOrderData();
    this._orderCompleted$$.next(order);

    this.cartService.refresh();
    return order;
  }

  // private trackOrderWithNextUser(order: Order) {
  //   const transaction = {
  //     affiliation: order.orderNumber,
  //     incomplete: 0,
  //     shipping: 0,
  //     tax: order.taxes ?? 0.0,
  //     currency: '$',
  //   };

  //   const prods = linq
  //     .from(order.products!)
  //     .select((product) => ({
  //       id: product.lineCode + product.partNumber,
  //       reference: 'sku',
  //       quantity: linq.from(product.branchQuantities!).sum((q) => q.quantity * product.sellMultiple),
  //       price: ProductUtil.getSalesPrice(product),
  //       name: product.description,
  //       amount: 0,
  //     }))
  //     .select((p) => {
  //       p.amount = p.quantity * p.price;
  //       return p;
  //     });

  //   const products = prods.toObject(
  //     (p) => p.id,
  //     (p) => p
  //   );

  //   const total = Math.floor(prods.sum((p) => p.quantity * (p.price * 100))) / 100;

  //   try {
  //     nextuser.purchase(total, products, transaction);
  //     nextuser.track('purchase_completed');
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  public getOrder(orderId: string, email?: string): Observable<Order> {
    return this.ordersApi.getOrderById(orderId, email);
  }

  public getOrderList(customerNumber?: string, skip?: number, limit?: number): Observable<OrderSearchListResult> {
    return this.ordersApi.getOrders(skip, limit, customerNumber);
  }
}
